/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import { mat4 } from 'gl-matrix';
import { regl } from '~js/renderer';
import gui from '~js/helpers/gui';
var CONFIG = {
  fov: 45,
  near: 0.01,
  far: 1000
};
gui.get(function (gui) {
  var folder = gui.addFolder('Camera');
  folder.add(CONFIG, 'fov', 0, 200);
});
var cameraConfig = {
  eye: [0, 0, 6],
  target: [0, 0, 0],
  up: [0, 1, 0]
};
export default regl({
  context: {
    projection: function projection(_ref) {
      var viewportWidth = _ref.viewportWidth,
          viewportHeight = _ref.viewportHeight;
      var fov = CONFIG.fov,
          near = CONFIG.near,
          far = CONFIG.far;
      var fovy = fov * Math.PI / 180;
      var aspect = viewportWidth / viewportHeight;
      return mat4.perspective([], fovy, aspect, near, far);
    },
    view: function view(context, props) {
      var config = Object.assign({}, cameraConfig, props);
      var eye = config.eye,
          target = config.target,
          up = config.up;
      return mat4.lookAt([], eye, target, up);
    },
    fov: function fov() {
      var fov = CONFIG.fov;
      return fov;
    }
  },
  uniforms: {
    u_projection: regl.context('projection'),
    u_view: regl.context('view'),
    u_cameraPosition: regl.context('eye'),
    u_resolution: function u_resolution(_ref2) {
      var viewportWidth = _ref2.viewportWidth,
          viewportHeight = _ref2.viewportHeight;
      return [viewportWidth, viewportHeight];
    }
  }
});