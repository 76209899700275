/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import * as dat from 'dat.gui';
import queryString from 'query-string';
var gui;

var init = function init() {
  if (!gui) {// gui = new dat.GUI({width: 300})
  }
};

setTimeout(function () {
  var parsed = queryString.parse(location.search);
  var debugMode = parsed.debug === 'true';

  if (debugMode || devMode) {
    init();
  }
});
export default {
  get: function get(callback) {
    setTimeout(function () {
      if (gui) {
        callback(gui);
      }
    });
  }
};