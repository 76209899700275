/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import Regl from 'regl';
export var regl = Regl({
  container: document.querySelector('.content'),
  attributes: {
    antialias: true,
    alpha: false
  }
});
var tick;
export var play = function play(action) {
  if (!tick) {
    tick = regl.frame(action);
  }
};
export var stop = function stop() {
  if (tick) {
    tick.cancel();
    tick = null;
  }
};