/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import { mat4, vec3 } from 'gl-matrix';
import { regl } from '~js/renderer';
import camera from '~js/camera';
import plane from './plane';
import reflector from './reflector';
export var planes = [{
  position: [1, 0, 0],
  normal: [1, 0, 0],
  rotation: -Math.PI * 0.5,
  axis: [0, 1, 0],
  uvRotation: Math.PI
}, {
  position: [-1, 0, 0],
  normal: [-1, 0, 0],
  rotation: Math.PI * 0.5,
  axis: [0, 1, 0],
  uvRotation: Math.PI
}, {
  position: [0, 1, 0],
  normal: [0, 1, 0],
  rotation: Math.PI * 0.5,
  axis: [1, 0, 0],
  uvRotation: 0
}, {
  position: [0, -1, 0],
  normal: [0, -1, 0],
  rotation: -Math.PI * 0.5,
  axis: [1, 0, 0],
  uvRotation: 0
}, {
  position: [0, 0, 1],
  normal: [0, 0, 1],
  rotation: Math.PI,
  axis: [0, 1, 0],
  uvRotation: Math.PI
}, {
  position: [0, 0, -1],
  normal: [0, 0, -1],
  rotation: 0,
  axis: [0, 1, 0],
  uvRotation: Math.PI
}];
var renderTarget = regl.framebuffer();

var reflect = function reflect(a, b) {
  var dot2 = new Array(3);
  dot2.fill(2 * vec3.dot(b, a));
  return vec3.sub([], a, vec3.mul([], dot2, b));
};

var setup = regl({
  context: {
    config: function config(context, _ref, batchId) {
      var mainCameraConfig = _ref.cameraConfig,
          rotationMatrix = _ref.rotationMatrix;
      var _planes$batchId = planes[batchId],
          position = _planes$batchId.position,
          normal = _planes$batchId.normal,
          rotation = _planes$batchId.rotation,
          axis = _planes$batchId.axis;
      var planeMatrix = mat4.translate([], rotationMatrix, position);
      var normalMatrix = mat4.translate([], rotationMatrix, normal);
      mat4.rotate(planeMatrix, planeMatrix, rotation, axis);
      var planeWorldPosition = mat4.getTranslation([], planeMatrix);
      var planeWorldNormal = mat4.getTranslation([], normalMatrix);
      var cameraWorldPosition = mainCameraConfig.eye;
      var eye = [0, 0, 0];
      vec3.sub(eye, planeWorldPosition, cameraWorldPosition);
      eye = reflect(eye, planeWorldNormal);
      vec3.negate(eye, eye);
      vec3.add(eye, eye, planeWorldPosition);
      var lookAtPosition = [0, 0, -1];
      vec3.add(lookAtPosition, lookAtPosition, cameraWorldPosition);
      var target = [0, 0, 0];
      vec3.sub(target, planeWorldPosition, lookAtPosition);
      target = reflect(target, planeWorldNormal);
      vec3.negate(target, target);
      vec3.add(target, target, planeWorldPosition);
      var up = [0, 1, 0];
      up = reflect(up, planeWorldNormal);
      var cameraConfig = {
        eye: eye,
        target: target,
        up: up
      };
      return {
        cameraConfig: cameraConfig,
        planeMatrix: planeMatrix
      };
    },
    uvRotation: function uvRotation(context, props, batchId) {
      var uvRotation = planes[batchId].uvRotation;
      return uvRotation;
    },
    faceFbo: function faceFbo(context, _ref2, batchId) {
      var reflectionFbo = _ref2.reflectionFbo;
      return reflectionFbo.faces[batchId];
    }
  }
});
export default (function (_ref3) {
  var reflectionFbo = _ref3.reflectionFbo,
      cameraConfig = _ref3.cameraConfig,
      rotationMatrix = _ref3.rotationMatrix,
      texture = _ref3.texture;
  var props = new Array(6);
  props.fill({
    reflectionFbo: reflectionFbo,
    cameraConfig: cameraConfig,
    rotationMatrix: rotationMatrix
  });
  setup(props, function (_ref4) {
    var viewportWidth = _ref4.viewportWidth,
        viewportHeight = _ref4.viewportHeight,
        config = _ref4.config,
        uvRotation = _ref4.uvRotation,
        faceFbo = _ref4.faceFbo;
    var textureMatrix = mat4.fromValues(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0.5, 0, 0.5, 0.5, 0.5, 1);
    renderTarget.resize(viewportWidth, viewportHeight);
    renderTarget.use(function () {
      regl.clear({
        color: [0, 0, 0, 0],
        depth: 1
      });
      camera(config.cameraConfig, function (_ref5) {
        var projection = _ref5.projection,
            view = _ref5.view,
            fov = _ref5.fov;
        mat4.multiply(textureMatrix, textureMatrix, projection);
        mat4.mul(textureMatrix, textureMatrix, view);
        mat4.mul(textureMatrix, textureMatrix, config.planeMatrix);
        reflector({
          texture: texture,
          cameraConfig: cameraConfig,
          fov: fov
        });
      });
    });
    faceFbo.use(function () {
      regl.clear({
        color: [0, 0, 0, 0],
        depth: 1
      });
      plane({
        texture: renderTarget,
        textureMatrix: textureMatrix,
        uvRotation: uvRotation
      });
    });
  });
});