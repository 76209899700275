/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import Stats from 'stats.js';
var stats;

var init = function init() {
  stats = new Stats(); // stats.showPanel(0)
  // document.body.appendChild(stats.dom)
};

devMode && init();
export default {
  begin: function begin() {
    if (stats) {
      stats.begin();
    }
  },
  end: function end() {
    if (stats) {
      stats.end();
    }
  }
};