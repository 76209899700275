/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import { mat4 } from 'gl-matrix';
import stats from '~js/helpers/stats';
import gui from '~js/helpers/gui';
import Texture from '~js/helpers/Texture';
import { regl, play } from '~js/renderer';
import camera from '~js/camera';
import cube, { Types as CubeTypes, Faces as CubeFaces, Masks as CubeMasks } from '~js/components/cube';
import content, { Types as ContentTypes } from '~js/components/content';
import reflection from '~js/components/reflection';
import '~css/main.css';
var CONFIG = {
  cameraX: 0,
  cameraY: 0,
  cameraZ: 5.7,
  rotation: 4.8,
  rotateX: 1,
  rotateY: 1,
  rotateZ: 1,
  velocity: 0.009
};
gui.get(function (gui) {
  var folder = gui.addFolder('Main');
  folder.add(CONFIG, 'cameraX', -20, 20).step(0.1);
  folder.add(CONFIG, 'cameraY', -20, 20).step(0.1);
  folder.add(CONFIG, 'cameraZ', -20, 20).step(0.1);
  folder.add(CONFIG, 'rotation', -5, 5).step(0.0001);
  folder.add(CONFIG, 'rotateX', 0, 10).step(0.1);
  folder.add(CONFIG, 'rotateY', 0, 10).step(0.1);
  folder.add(CONFIG, 'rotateZ', 0, 10).step(0.1);
  folder.add(CONFIG, 'velocity', 0, 0.05).step(0.0001);
});
/**
 * Fbos
 */

var displacementFbo = regl.framebuffer();
var maskFbo = regl.framebuffer();
var contentFbo = regl.framebuffer();
var reflectionFbo = regl.framebufferCube(1024);
/**
 * Textures
 */

var textures = [{
  texture: Texture(regl, 'logo.png'),
  typeId: ContentTypes.RAINBOW,
  maskId: CubeMasks.M1
}, {
  texture: Texture(regl, 'logo.png'),
  typeId: ContentTypes.BLUE,
  maskId: CubeMasks.M2
}, {
  texture: Texture(regl, 'logo.png'),
  typeId: ContentTypes.RED,
  maskId: CubeMasks.M3
}, {
  texture: Texture(regl, 'text-1.png'),
  typeId: ContentTypes.BLUE,
  maskId: CubeMasks.M4
}, {
  texture: Texture(regl, 'text-2.png'),
  typeId: ContentTypes.RED,
  maskId: CubeMasks.M5
}];

var animate = function animate(_ref) {
  var viewportWidth = _ref.viewportWidth,
      viewportHeight = _ref.viewportHeight,
      tick = _ref.tick;
  stats.begin();
  var rotation = CONFIG.rotation,
      rotateX = CONFIG.rotateX,
      rotateY = CONFIG.rotateY,
      rotateZ = CONFIG.rotateZ,
      velocity = CONFIG.velocity,
      cameraX = CONFIG.cameraX,
      cameraY = CONFIG.cameraY,
      cameraZ = CONFIG.cameraZ;
  /**
   * Resize Fbos
   */

  displacementFbo.resize(viewportWidth, viewportHeight);
  maskFbo.resize(viewportWidth, viewportHeight);
  contentFbo.resize(viewportWidth, viewportHeight);
  /**
   * Rotation Matrix
   */

  var factor = tick * velocity;
  var rotationMatrix = mat4.create();
  mat4.rotate(rotationMatrix, rotationMatrix, rotation, [rotateX, rotateY, rotateZ]);
  mat4.rotate(rotationMatrix, rotationMatrix, factor, [Math.cos(factor), Math.sin(factor), 0.5]);
  /**
   * Camera config
   */

  var cameraConfig = {
    eye: [cameraX, cameraY, cameraZ],
    target: [0, 0, 0]
  };
  /**
   * Clear context
   */

  regl.clear({
    color: [0, 0, 0, 0],
    depth: 1
  });
  camera(cameraConfig, function () {
    /**
     * Render the displacement into the displacementFbo
     * Render the mask into the displacementFbo
     */
    cube([{
      fbo: displacementFbo,
      cullFace: CubeFaces.BACK,
      typeId: CubeTypes.DISPLACEMENT,
      matrix: rotationMatrix
    }, {
      fbo: maskFbo,
      cullFace: CubeFaces.BACK,
      typeId: CubeTypes.MASK,
      matrix: rotationMatrix
    }]);
    /**
     * Render the content to print in the cube
     */

    contentFbo.use(function () {
      content({
        textures: textures,
        displacement: displacementFbo,
        mask: maskFbo
      });
    });
  });
  /**
   * Render the content reflection
   */

  reflection({
    reflectionFbo: reflectionFbo,
    cameraConfig: cameraConfig,
    rotationMatrix: rotationMatrix,
    texture: contentFbo
  });
  camera(cameraConfig, function () {
    /**
     * Render the back face of the cube
     * Render the front face of the cube
     */
    cube([{
      cullFace: CubeFaces.FRONT,
      typeId: CubeTypes.FINAL,
      reflection: reflectionFbo,
      matrix: rotationMatrix
    }, {
      cullFace: CubeFaces.BACK,
      typeId: CubeTypes.FINAL,
      texture: contentFbo,
      matrix: rotationMatrix
    }]);
  });
  stats.end();
};

var init = function init() {
  play(animate);
};

init();