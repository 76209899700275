/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
export default (function (regl, src) {
  var texture = regl.texture();
  var image = new Image();
  image.src = require("~assets/".concat(src));

  image.onload = function () {
    texture({
      data: image,
      flipY: true,
      min: 'mipmap'
    });
  };

  return texture;
});