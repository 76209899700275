/**
 * @author Lorenzo Cadamuro / http://lorenzocadamuro.com
 */
import { mat4 } from 'gl-matrix';
import { regl } from '~js/renderer';
import gui from '~js/helpers/gui';
import { positions, uv, elements } from './config';
import frag from './shader.frag';
import vert from './shader.vert';
var CONFIG = {
  depthOpacity: 0.25
};
gui.get(function (gui) {
  var folder = gui.addFolder('Reflector');
  folder.add(CONFIG, 'depthOpacity', 0, 1).step(0.01);
});
export default regl({
  frag: frag,
  vert: vert,
  context: {
    world: function world(_ref, _ref2) {
      var viewportWidth = _ref.viewportWidth,
          viewportHeight = _ref.viewportHeight;
      var mainCameraConfig = _ref2.cameraConfig,
          fov = _ref2.fov;
      var fovy = fov * Math.PI / 180;
      var aspect = viewportWidth / viewportHeight;
      var cameraHeight = Math.tan(fovy / 2) * mainCameraConfig.eye[2];
      var cameraWidth = cameraHeight * aspect;
      var world = mat4.create();
      mat4.scale(world, world, [cameraWidth, cameraHeight, 1.0]);
      return world;
    },
    depthOpacity: function depthOpacity() {
      var depthOpacity = CONFIG.depthOpacity;
      return depthOpacity;
    }
  },
  attributes: {
    a_position: positions,
    a_uv: uv
  },
  uniforms: {
    u_world: regl.context('world'),
    u_texture: regl.prop('texture'),
    u_depthOpacity: regl.context('depthOpacity')
  },
  depth: {
    enable: true,
    mask: false,
    func: 'less'
  },
  blend: {
    enable: true,
    func: {
      srcRGB: 'src alpha',
      srcAlpha: 1,
      dstRGB: 'one minus src alpha',
      dstAlpha: 1
    },
    equation: {
      rgb: 'add',
      alpha: 'add'
    },
    color: [0, 0, 0, 0]
  },
  elements: elements,
  count: 6
});